.row {
  width: 100%;
  padding: 0;
  margin: 0;
}


@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

#freecoding {
  position: static;
  width: 100%;
  height: 100%;
  padding: 0px 5px;
}

#freecoding-tabs {
  position:static;
  height: 30px;
  width: 100%;
  /* border: 1px solid black */
  z-index: 1000;
  /* border-bottom: 1px solid rgba(230,230,230,1); */
  /* box-shadow: 0px 5px 5px 0px #dad9d9; */
}

.tab {
  height: 100%;
  /* border: 1px solid black; */
  width: 33.33%;
  float: left;
  border-radius: 10px 10px 0px 0px;
  background-color: rgba(255, 255, 255, 0.9);
  text-align: center;
  line-height: 30px;
  vertical-align: middle;
  z-index: 1000;
  /* border-bottom: 1px solid rgba(230,230,230,1);
  border-right: 1px solid rgba(230,230,230,1); */
  /* border: 1px solid rgba(200,200,200,1); */
}

.tab:hover {
  cursor: pointer;
}

.active-tab {
  background: linear-gradient(to right, rgba(255, 154, 124, 1) ,rgba(255, 87, 112));
  /* box-shadow: 0px 0px 30px 1px #c0c0c0; */
  color: white;
}

#editor-container {
  box-sizing: border-box;
  height: calc(100% - 30px);
  position: static;
  padding-top: 0px;
  width: 100%;

 
}

#editor {
  box-sizing: content-box;
  width: 100%;
  height: 100%;
}
