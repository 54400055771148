#sidebar-container {
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,.9);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    -webkit-transition: all 0,5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.btn-toggleSidebar {
    position: absolute;
    right: 5px;
    top: 5px;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    /* background-color: rgba(255,255,255,0.5); */
    border: none;
    outline:none;
    outline-style: none;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 40px;
    vertical-align: middle;
    -webkit-transition: all 0,5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

#sidebar-wrapper.toggled .btn-toggleSidebar {
    transform: rotate(180deg);
}

#top-container {
    width: 100%;
    height: 60px;
}

#profile-pic-container {
    width: 20px;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    /* border: 1px solid black; */
}

#profile-pic {
    width: 80%;
    height: auto;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0px 0px 10px 1px #888888;
}

#rank-icon {
    width: 30%;
    position: absolute;
    top: 170px;
    margin-left: -225px;
    -webkit-transition: all 0,5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.robotIdContainer {
    text-align: center;
}

#robotLogo {
    position: relative;
    height: 50px;
    margin: 50px 0 5px 0;
}

#sidebar-wrapper.toggled #rank-icon {
    margin-left: -145px;
}

#user-name-container {
    position: absolute;
    width: 200px;
    top: 250px;
    margin-left: -180px;
    text-align: left;
    font-family: 'HelveticaNeue', sans-serif;
    -webkit-transition: all 0,5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

#sidebar-wrapper.toggled #user-name-container {
    margin-left: 20px;
}

#categories-container {
    position: absolute;
    width: 100%;
    height: auto;
    bottom: 50px;
    /* border: 1px solid black; */
}

.category-container {
    width: 100%;
    height: 50px;
    /* border: 1px solid black; */
}

.category-btn {
    position: absolute;;
    width: 190px;
    margin-top: 2px;
    height: 46px;
    /* border: 1px solid black; */
    right: 5px;
    border-radius: 25px;
    
}

.active {
    background: linear-gradient(to right, rgba(255, 154, 124, 1) ,rgba(255, 87, 112));
    box-shadow: 0px 0px 30px 1px #c0c0c0;
    color: white;
}

.category-icon-container {
    position: absolute;
    right: 5px;
    margin-top: 5px;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    /* background-color: rgba(255,255,255,0.5); */
    border: none;
    outline:none;
    outline-style: none;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* box-shadow: 0px 0px 30px 1px #afafaf; */
    line-height: 40px;
    vertical-align: middle
}

.category-btn:hover {
    cursor: pointer;
}

.btn-toggleSidebar:hover {
    cursor: pointer;
}

.category-icon {
    font-size: 1.8em;
    margin-top: 6px;
    margin-right: -5px;
    -webkit-transition: all 0,5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.fa-chevron-right {
    margin-top: 10px;
    margin-left: 3px;
}

.category-name {
    width: 130px;
    margin-left: -130px;
    float: left;
    text-align: right;
    line-height: 50px;
    vertical-align: middle; 
    -webkit-transition: all 0,5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

/* #sidebar-wrapper.toggled .free-coding-name {
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
}

#sidebar-wrapper.toggled .story-mode-name {
    -webkit-transition: all 1.4s ease;
    -moz-transition: all 1.4s ease;
    -o-transition: all 1.4s ease;
    transition: all 1.4s ease;
}

#sidebar-wrapper.toggled .documentation-name {
    -webkit-transition: all 1.8s ease;
    -moz-transition: all 1.8s ease;
    -o-transition: all 1.8s ease;
    transition: all 1.8s ease;
}

#sidebar-wrapper.toggled .settings-name {
    -webkit-transition: all 2.2s ease;
    -moz-transition: all 2.2s ease;
    -o-transition: all 2.2s ease;
    transition: all 2.2s ease;
} */

#sidebar-wrapper.toggled .category-name {
    margin-left: 0px;
}

