.robotId-input {
  border: none;
  border-bottom: 1px solid grey;
  background-color: transparent;
  width: 100%;
  padding: 12px 2px;
  margin: 2px 0;
  display: inline-block;
  box-sizing: border-box;
  outline: none;
}

.connect-button-container {
  width: 100%;
  text-align: center;
}

.connect-button {
  font-size: 14px;
  color: white;
  width: 100%;
  height: 40px;
  background: linear-gradient(
    to right,
    rgba(255, 154, 124, 1),
    rgba(255, 87, 112)
  );
  border-radius: 20px;
  margin: 30px 0px;
  border: none;
  box-shadow: 0px 0px 20px 1px #d3d3d3;
  cursor: pointer;
}

input[type='text'],
input[type='password'],
textarea,
select {
  outline: none;
}

*:focus {
  outline: none;
}
