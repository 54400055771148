@import url(https://fonts.googleapis.com/css?family=Merriweather:100,200,300,300i,400,400i,700);
@import url(https://fonts.googleapis.com/css?family=Roboto+Slab:400,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400);
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

body {
  font-family: 'HelveticaNeue-Light', sans-serif;
  color: rgba(63,52,100,1);
}

.container-fluid {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

.row {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

.wrapper {
  /* position: fixed; */
  background-image: url(/static/media/space-background.ed946ca8.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  /* background-position: center; */
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  /*justify-content: center;*/
  /* align-items: center;
  padding-left: 0; */
  transition: all 0.5s ease;
}


#sidebar-wrapper {
  top: 2%;
  z-index: 1000;
  position: fixed;
  left: 200px;
  width: 50px;
  height: 96%;
  margin-left: -200px;
  overflow-y: none;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: all 0.5s ease;
}

#content-wrapper {
  position: absolute;
  height: 96%;
  width: 100%;
  padding-left: 55px;
  margin-top: 2vh;
  padding-right: 5px;
  /* border: 1px solid black; */
  transition: all 0.5s ease;
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
       animation: fadein 1s;
}

#sidebar-wrapper.toggled {
  width: 200px;
}

#content-wrapper.toggled {
  padding-left: 205px;
}

.content-container {
  /* width: 50px; */
  height: 100%;
  padding: 0;
  padding-left: 5px;
  padding-right: 5px;
  margin: 0;
  position: relative;
  /* -webkit-transition: all 0,5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease; */
}

.content-container__inner {
  width: 100%;
  height: 100%;
  padding: 0;
  top: 0;
  /* padding: 10px; */
  /* opacity: 0.9; */
  position: relative;
  background-color: #ffffff99;
  /*background-color: rgba(27, 26, 26, 0.2);*/
  border-radius: 10px;
  transition: all 0.5s ease;
}

.no-background {
  background-color: rgba(0,0,0,0);
}

.col-sm-12 {
  padding: 0;
}
.col-xs-12 {
  padding: 0;
}

.no-padding {
  padding: 0;
}

#sidebar-container {
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,.9);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    transition: all 0.5s ease;
}

.btn-toggleSidebar {
    position: absolute;
    right: 5px;
    top: 5px;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    /* background-color: rgba(255,255,255,0.5); */
    border: none;
    outline:none;
    outline-style: none;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 40px;
    vertical-align: middle;
    transition: all 0.5s ease;
}

#sidebar-wrapper.toggled .btn-toggleSidebar {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

#top-container {
    width: 100%;
    height: 60px;
}

#profile-pic-container {
    width: 20px;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    /* border: 1px solid black; */
}

#profile-pic {
    width: 80%;
    height: auto;
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    box-shadow: 0px 0px 10px 1px #888888;
}

#rank-icon {
    width: 30%;
    position: absolute;
    top: 170px;
    margin-left: -225px;
    transition: all 0.5s ease;
}

.robotIdContainer {
    text-align: center;
}

#robotLogo {
    position: relative;
    height: 50px;
    margin: 50px 0 5px 0;
}

#sidebar-wrapper.toggled #rank-icon {
    margin-left: -145px;
}

#user-name-container {
    position: absolute;
    width: 200px;
    top: 250px;
    margin-left: -180px;
    text-align: left;
    font-family: 'HelveticaNeue', sans-serif;
    transition: all 0.5s ease;
}

#sidebar-wrapper.toggled #user-name-container {
    margin-left: 20px;
}

#categories-container {
    position: absolute;
    width: 100%;
    height: auto;
    bottom: 50px;
    /* border: 1px solid black; */
}

.category-container {
    width: 100%;
    height: 50px;
    /* border: 1px solid black; */
}

.category-btn {
    position: absolute;;
    width: 190px;
    margin-top: 2px;
    height: 46px;
    /* border: 1px solid black; */
    right: 5px;
    border-radius: 25px;
    
}

.active {
    background: linear-gradient(to right, rgba(255, 154, 124, 1) ,rgba(255, 87, 112));
    box-shadow: 0px 0px 30px 1px #c0c0c0;
    color: white;
}

.category-icon-container {
    position: absolute;
    right: 5px;
    margin-top: 5px;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    /* background-color: rgba(255,255,255,0.5); */
    border: none;
    outline:none;
    outline-style: none;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* box-shadow: 0px 0px 30px 1px #afafaf; */
    line-height: 40px;
    vertical-align: middle
}

.category-btn:hover {
    cursor: pointer;
}

.btn-toggleSidebar:hover {
    cursor: pointer;
}

.category-icon {
    font-size: 1.8em;
    margin-top: 6px;
    margin-right: -5px;
    transition: all 0.5s ease;
}

.fa-chevron-right {
    margin-top: 10px;
    margin-left: 3px;
}

.category-name {
    width: 130px;
    margin-left: -130px;
    float: left;
    text-align: right;
    line-height: 50px;
    vertical-align: middle;
    transition: all 0.5s ease;
}

/* #sidebar-wrapper.toggled .free-coding-name {
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
}

#sidebar-wrapper.toggled .story-mode-name {
    -webkit-transition: all 1.4s ease;
    -moz-transition: all 1.4s ease;
    -o-transition: all 1.4s ease;
    transition: all 1.4s ease;
}

#sidebar-wrapper.toggled .documentation-name {
    -webkit-transition: all 1.8s ease;
    -moz-transition: all 1.8s ease;
    -o-transition: all 1.8s ease;
    transition: all 1.8s ease;
}

#sidebar-wrapper.toggled .settings-name {
    -webkit-transition: all 2.2s ease;
    -moz-transition: all 2.2s ease;
    -o-transition: all 2.2s ease;
    transition: all 2.2s ease;
} */

#sidebar-wrapper.toggled .category-name {
    margin-left: 0px;
}


.row {
  width: 100%;
  padding: 0;
  margin: 0;
}


@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}


@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

#freecoding {
  position: static;
  width: 100%;
  height: 100%;
  padding: 0px 5px;
}

#freecoding-tabs {
  position:static;
  height: 30px;
  width: 100%;
  /* border: 1px solid black */
  z-index: 1000;
  /* border-bottom: 1px solid rgba(230,230,230,1); */
  /* box-shadow: 0px 5px 5px 0px #dad9d9; */
}

.tab {
  height: 100%;
  /* border: 1px solid black; */
  width: 33.33%;
  float: left;
  border-radius: 10px 10px 0px 0px;
  background-color: rgba(255, 255, 255, 0.9);
  text-align: center;
  line-height: 30px;
  vertical-align: middle;
  z-index: 1000;
  /* border-bottom: 1px solid rgba(230,230,230,1);
  border-right: 1px solid rgba(230,230,230,1); */
  /* border: 1px solid rgba(200,200,200,1); */
}

.tab:hover {
  cursor: pointer;
}

.active-tab {
  background: linear-gradient(to right, rgba(255, 154, 124, 1) ,rgba(255, 87, 112));
  /* box-shadow: 0px 0px 30px 1px #c0c0c0; */
  color: white;
}

#editor-container {
  box-sizing: border-box;
  height: calc(100% - 30px);
  position: static;
  padding-top: 0px;
  width: 100%;

 
}

#editor {
  box-sizing: content-box;
  width: 100%;
  height: 100%;
}

#blockly-container {
    position: relative;
    width: 100%;
    height: 100%;
    /* border: 1px solid black; */
    left: 0;
    top: 0;
}

/* #blockly-container > div:first-child > div{
  left: 0 !important;
  top: 0 !important;
  position: static !important;
  width: 100% !important;
} */

.blocklyDrawer {
    height: 100%;
}

.blocklyWorkspace {
    background: none;
    background-color: none;
    width: 100%;
}

.blocklyMainBackground {
    left: 0;
    top: 0;
    /* position: absolute; */
    width: 100%;
    border-radius: 0px 0px 10px 10px;
    opacity: 0.9;
    background-color: rgba(32, 30, 30, 0.9);
    background: none;
    fill: none;
}

.blocklyToolboxDiv {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 10px;
}

.injectionDiv {
    border-radius: 0px 0px 10px 10px;
    background-color: none;
}

#btn-container {
    left: 120px;
    bottom: 20px;
    position: absolute;
    width: auto;
    height: 50px;
    /* border: 1px solid black; */
    text-align: center;
}

.btn-blockly-editor {
    float: left;
    border-radius: 25px;
    margin-right: 20px;
    width: 50px;
    height: 50px;
    background-color: rgba(255,255,255,0.9);
    box-shadow: 0px 0px 10px 0.5px #888888;
    text-align: center;
    line-height: 50px;
    vertical-align: middle;
    font-size: 2.2em;
    color: rgba(63,52,100,1);
}

.blockly-buttons:hover {
    background-color: #ff7675;
}
.hax{
  display: flex;
  height: 100%;
  flex-wrap:wrap;
}

.bg-white {
  background-color: rgba(255,255,255,1);
  opacity: 1;
}

/* Used: http://csshexagon.com/ to create hexagon */

.hax{
  display: flex;
  height: 100%;
  flex-wrap:wrap;
}

.content-container__inner{
  position:relative;
  display:inline-block;
}

.buttongroup{
  width: 100%;
}

.buttongroup .button {
  border: none;
  background-color: #64C7CC;
  border-radius: 50%;
  width: 10em;
  height: 10em;
  color: white;
  padding: 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  opacity: 0.6;
  transition: 0.3s;
  z-index: 1;
}

.buttongroup .button:hover{opacity: 1}



.robotId-input {
  border: none;
  border-bottom: 1px solid grey;
  background-color: transparent;
  width: 100%;
  padding: 12px 2px;
  margin: 2px 0;
  display: inline-block;
  box-sizing: border-box;
  outline: none;
}

.connect-button-container {
  width: 100%;
  text-align: center;
}

.connect-button {
  font-size: 14px;
  color: white;
  width: 100%;
  height: 40px;
  background: linear-gradient(
    to right,
    rgba(255, 154, 124, 1),
    rgba(255, 87, 112)
  );
  border-radius: 20px;
  margin: 30px 0px;
  border: none;
  box-shadow: 0px 0px 20px 1px #d3d3d3;
  cursor: pointer;
}

input[type='text'],
input[type='password'],
textarea,
select {
  outline: none;
}

*:focus {
  outline: none;
}

 .login-wrapper {
   position: fixed;
    height: 100vh;
    width: 100vw;
    vertical-align: middle;
    text-align: center;
    line-height: 100%;
    padding-top: 100px;
    transition: all 0.5s ease;
  } 

.loginBox {
  position: relative;
  vertical-align: middle;
  display: inline-block;
  width: 360px;
  height: auto;
  padding: 20px 60px;
  /* height: 500px; */
  border-radius: 10px;
  background-color: rgba(255,255,255,0.95);
  transition: all 0.5s ease;
}

.login-header {
  width: 100%;
  height: 200px;
  padding: 10px 30px;
}

#logo {
  width: 100%;
}

/* .signUp-button-container {
  width: 100%;
  text-align: center;
} */


.loading-gear {
    font-size:100px
}

*:focus {
    outline: none;
}
.failed-icon {
    font-size:50px;
}

*:focus {
    outline: none;
}
