 .login-wrapper {
   position: fixed;
    height: 100vh;
    width: 100vw;
    vertical-align: middle;
    text-align: center;
    line-height: 100%;
    padding-top: 100px; 
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  } 

.loginBox {
  position: relative;
  vertical-align: middle;
  display: inline-block;
  width: 360px;
  height: auto;
  padding: 20px 60px;
  /* height: 500px; */
  border-radius: 10px;
  background-color: rgba(255,255,255,0.95);
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.login-header {
  width: 100%;
  height: 200px;
  padding: 10px 30px;
}

#logo {
  width: 100%;
}

/* .signUp-button-container {
  width: 100%;
  text-align: center;
} */

